<template>
  <div class="cpt-login_bg">
    <video class="login-bg_video" autoplay muted loop>
      <source src="@/assets/videos/bg.mp4" type="video/mp4" />
    </video>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.cpt-login_bg {
  vertical-align: bottom;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 1;
  .login-bg_video {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -100;
    right: 0;
    bottom: 0;
    // background: url(../img/index-image.jpg) no-repeat center center;
    // background-size: cover;
    overflow: hidden;
  }
}
</style>