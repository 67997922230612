<template>
  <div class="cpt-login_form">
    <div class="form-header">
      <div class="imgbg"></div>
      <!-- <div class="header-title_l animate__animated animate__bounceInLeft"></div>
      <div class="header-title animate__animated animate__bounceInDown">
        <img src="~@/assets/login_title.png" alt="" />
      </div>
      <div
        class="header-title_r animate__animated animate__bounceInRight"
      ></div> -->
    </div>
    <div class="form-body animate__animated animate__fadeInUp">
      <div class="body-inner">
        <div class="body-inner-right mt30"></div>
        <div class="body-inner-left">
          <div class="body-form_header">
            <div class="form-title_tabs">
              <div
                @click="type = 1"
                class="tab-item"
                :class="{ selected: type == 1 }"
              >
                用户名密码登录
              </div>
              <!-- <div
                @click="type = 2"
                class="tab-item"
                :class="{ selected: type == 2 }"
              >
                数字证书登录
              </div> -->
            </div>
          </div>
          <div class="body-form_body">
            <FormAccount v-show="type == 1" />
            <FormCert v-show="type == 2" @getKey="type = 2" />
          </div>
        </div>
        <div class="body-inner-left-link">
          <a
            href="http://32.128.6.52:4500/11.11平台部署/ChromeStandaloneSetup64.exe"
            >本平台仅适用Google Chrome下载>></a
          >
        </div>
        <div @click="handle_click" class="body-inner-back-link">后台管理</div>
      </div>
      <div
        class="animate__animated animate__zoomInDown"
        style="position: absolute; z-index: 2000"
      >
        <img
          class="body-uav"
          src="~@/assets/images/login_uav.png"
          alt="body-uav"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormAccount from "./account";
import FormCert from "./cert";
export default {
  components: { FormAccount, FormCert },
  data() {
    return {
      type: 1,
    };
  },
  methods: {
    handle_click() {
      window.open(
        `${process.env.VUE_APP_LINK_URL}/hawk_back/#/login`,
        "_blank"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-login_form {
  position: relative;
  height: 600px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 196px;
  .form-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 48px;
    .imgbg {
      width: 900px;
      height: 80px;
      background: url("~@/assets/images/login_form_title_arrow21.png") no-repeat;
    }
    .header-title_l {
      height: 24px;
      width: 331px;
      background: url("~@/assets/images/login_form_title_arrow.png") no-repeat;
      user-select: none;
    }
    .header-title {
      font-family: MicrosoftYaHei-Bold;
      font-size: 48px;
      color: #1f7cc8;
      letter-spacing: 0;
      text-align: center;
      font-weight: 700;
      margin: 0 40px;
      white-space: nowrap;
    }
    .header-title_r {
      height: 24px;
      width: 331px;
      background: url("~@/assets/images/login_form_title_arrow2.png") no-repeat;
      transform: rotate(180deg);
    }
  }
  .form-body {
    // width: 802px;
    width: 1033px;
    height: 543px;
    // background: linear-gradient(157deg, transparent 17px, #161d2d 0) top left,
    //   linear-gradient(0deg, transparent 0px, #161d2d 0) top right,
    //   linear-gradient(-24deg, transparent 15px, #161d2d 0) bottom right,
    //   linear-gradient(24deg, transparent 18px, #161d2d 0) bottom left;
    // background-size: 50% 50%;
    // background-repeat: no-repeat;
    position: relative;
    .body-inner {
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      // background: url("~@/assets/images/login_form_bg.png") no-repeat;
      background: url("~@/assets/images/login_form_bg2.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      display: flex;
      .body-inner-left-link {
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        color: #1f7cc8;
        position: absolute;
        right: 276px;
        bottom: 42px;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
        a {
          text-decoration: none;
          color: #1f7cc8;
        } /*正常的未被访问过的链接*/
        a:link {
          text-decoration: none;
        } /*已经访问过的链接*/
        a:visited {
          text-decoration: none;
        } /*鼠标划过(停留)的链接*/
        a:hover {
          text-decoration: none;
        } /* 正在点击的链接*/
        a:active {
          text-decoration: none;
        }
      }
      .body-inner-back-link {
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        color: #1f7cc8;
        position: absolute;
        right: 95px;
        bottom: 42px;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
      }
      .body-inner-right {
        width: 38%;
        height: 100%;
        margin-left: 80px;
        // background: url("~@/assets/images/logo3.png") no-repeat;
        background: url("~@/assets/images/logo4.svg") no-repeat;
        background-position: 10% 30%;
        background-size: 80%;
      }
      .body-inner-left {
        width: 438px;
      }
      .body-form_header {
        margin-top: 60px;
        margin-bottom: 10px;
        height: 56px;
        border: 1px solid transparent;
        // width: calc(100% + 72px);
        .form-title_tabs {
          display: flex;
          box-sizing: border-box;
          height: 100%;
          justify-content: space-between;
          .tab-item {
            width: 221px;
            // width: 386px;
            // flex: 1;
            height: 50px;
            line-height: 50px;
            text-align: center;
            color: #fff;
            font-family: PangMenZhengDao;
            font-size: 22px;
            text-align: center;
            font-weight: 400;
            position: relative;
            cursor: pointer;
            background: url("~@/assets/images/transparent.png") no-repeat;
            transition: 0.3s;
            transform-origin: center center;
            &::after {
              content: " ";
              display: block;
              position: absolute;
              bottom: 7px;
              left: 50%;
              transform: translateX(-50%);
              width: 124px;
              height: 4px;
              background: #1f7cc8;
              opacity: 0;
              transition: 0.3s;
            }
            &:last-child {
              margin-left: -50px;
            }
            &.selected:first-child {
              // background: url("~@/assets/images/login_type_0_bg.png") no-repeat;
              background: url("~@/assets/images/logo_right_bg.png") no-repeat;
              background-size: 221px 50px;
              &::after {
                opacity: 1;
              }
            }
            &.selected:last-child {
              // background: url("~@/assets/images/login_type_1_bg.png") no-repeat;
              background: url("~@/assets/images/logo_right_bg.png") no-repeat;
              background-size: 221px 50px;
              &::after {
                opacity: 1;
              }
            }
          }
        }
      }
      .body-form_body {
        width: 100%;
        height: calc(100% - 56px);
      }
    }
    .body-uav {
      display: none;
      position: absolute;
      left: 32px;
      top: -92px;
      z-index: 2;
      transform: translateX(-60%);
      user-select: none;
    }
  }
}
</style>
