<template>
  <div class="cpt-login_form_account animate__animated animate__fadeIn">
    <!-- <div class="account-title">用户登录</div> -->
    <el-form>
      <el-form-item class="custom-form_item">
        <div class="input_group">
          <div class="icon_box">
            <i class="iconfont icon-yonghuming" />
          </div>
          <el-input
            v-model="form.username"
            placeholder="请输入账号"
            clearable
          />
        </div>
      </el-form-item>

      <div class="tooltip">
        <el-tooltip
          class="item"
          :tabindex="-1"
          effect="dark"
          content="民警账号统一用警员编号登录，辅警账号统一用手机号码登录"
          placement="top"
        >
          <!-- <i class="el-icon-warning"></i> -->
        </el-tooltip>
      </div>
      <el-form-item class="custom-form_item" oncopy="return false">
        <div class="input_group">
          <div class="icon_box">
            <i class="iconfont icon-mima" />
          </div>
          <el-input
            id="password"
            type="password"
            show-password
            placeholder="请输入密码"
            v-model.trim="form.password"
            clearable
          />
        </div>
      </el-form-item>
      <el-form-item>
        <el-button
          :loading="loading"
          type="primary"
          class="login_btn"
          @click="handleLogin"
          >登录</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { getAccount, getPassword } from "@/utils/auth";
export default {
  data() {
    return {
      isShowPwd: false,
      loading: false,
      form: {
        username: "", //yancheng getAccount(),
        password: "", //mmcaabcE123456 getPassword(),
        remember: false,
      },
    };
  },
  methods: {
    ...mapActions("user", ["Login"]),
    handlePaste() {},
    async handleLogin() {
      let { username, password } = this.form;
      if (_.isEmpty(username)) {
        return this.$message.error("请输入账号");
      }
      if (_.isEmpty(password)) {
        return this.$message.error("请输入密码");
      }
      this.loading = true;
      this.Login(this.form)
        .then(() => {
          this.$el_message(
            "登录成功",
            () => {
              this.$router.replace("/").catch(() => {});
            },
            "success",
            1000
          );
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleKeyDown(e) {
      if (e.keyCode == 13) {
        this.handleLogin();
      }
    },
  },
  mounted() {
    document.body.addEventListener("keydown", this.handleKeyDown, false);
  },
  beforeRouteLeave(to, from, next) {
    document.body.removeEventListener("keydown", this.handleKeyDown);
  },
};
</script>

<style lang="scss" scoped>
.cpt-login_form_account {
  // width: 424px;
  height: 100%;
  margin: 0 auto;
  position: relative;

  .account-title {
    font-family: PangMenZhengDao;
    font-size: 22px;
    color: #dce9ff;
    text-align: center;
    font-weight: 400;
    margin-bottom: 18px;
    margin-top: 44px;
  }
  .tooltip {
    position: absolute;
    color: #2378c6;
    top: 20px;
    right: -30px;
    /deep/.item::before {
      font-size: 20px !important;
    }
  }
  ::v-deep .el-form {
    .el-form-item {
      margin-bottom: 32px;
      margin-top: 30px;
      &.custom-form_item {
        .el-form-item__content {
          background: #000000;
          border: 1px solid #2378c6;
          .input_group {
            display: flex;
            align-items: center;
            .icon_box {
              width: 34px;
              height: 34px;
              margin-left: 23px;
              margin-right: 6px;
              .iconfont {
                font-size: 30px;
                color: #2378c6;
              }
            }
            .el-input {
              .el-input__inner {
                background-color: transparent;
                height: 64px;
                box-sizing: border-box;
                background: #000000;
                border: none;
                border-radius: 0;

                font-family: MicrosoftYaHei;
                font-size: 16px;
                color: #ffffff;
                letter-spacing: 0;
                font-weight: 400;
              }
              &.password {
                user-select: none;
                .el-input__inner {
                  user-select: none;
                }
              }
            }
          }
        }
      }
      .el-button {
        height: 50px;
        width: 100%;
        border-radius: 0;
        background: #2378c6;
        font-family: PangMenZhengDao;
        font-size: 22px;
        color: #ffffff;
        text-align: center;
        font-weight: 400;
        border-color: #2378c6;
      }
    }
  }
}
</style>
