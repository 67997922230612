<template>
  <div class="cpt-login_form_cert animate__animated animate__fadeIn">
    <div class="cert-content">
      <i class="iconfont icon-shuzizhengshudenglu"></i>
      <div class="content-title">{{content}}</div>
    </div>
    <el-button type="primary">登录</el-button>
  </div>
</template>

<script>
export default {
  props: {
  },
  data(){
    return {
      ws: null,
      key: '',
    }
  },
  computed: {
    content(){
      if(this.key){
        return '已连接数字认证证书，登录中......';
      }else{
        return '请连接数字认证证书......';
      }
    }
  },
  watch: {
    key: {
      immediate: true,
      handler(){
        if(this.key){
          
        }
      }
    }
  },
  created(){
    this.ws = new WebSocket('ws://127.0.0.1:62022/YCGA');
    this.ws.onopen = () => {
      this.ws.onmessage = (e) => {
        // console.log('接收到加密狗服务消息', e);
        let data = null;
        try{
          data = JSON.parse(e.data);
        }catch(e){
          console.log(e);
        }

        if(!data){
          return;
        }

        if(data.type === '102'){
          // console.log('获取到加密狗的key', data.data);
          if(this.key !== data.data){
            this.key = data.data;
            this.$emit('getKey')
          }
        }
      }
      this.checkKey();
    }
  },
  methods: {
    checkKey(){
      if(!this.key){
        this.ws.send('{"cmd":"302"}');
        setTimeout(this.checkKey, 3000);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cpt-login_form_cert {
  height: 100%;
  // width: 424px;
  margin: 0 auto;
  .cert-content {
    display: flex;
    align-items: center;
    margin-bottom: 98px;
    margin-top: 90px;
    .iconfont {
      font-size: 30px;
      color: #2378c6;
      margin: 0 23px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .content-title {
      font-family: PangMenZhengDao;
      font-size: 22px;
      color: #dce9ff;
      text-align: center;
      font-weight: 400;
    }
  }
  ::v-deep .el-button {
    height: 50px;
    width: 100%;
    border-radius: 0;
    background: #2378c6;
    font-family: PangMenZhengDao;
    font-size: 22px;
    color: #ffffff;
    text-align: center;
    font-weight: 400;
  }
}
</style>